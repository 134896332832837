import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import './styles/style.css';
import './styles/mediaqueries.css';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import rightmark from "./img/righttick.svg";
import { Modal } from "react-bootstrap";
import SuccessMessage from "./successMessage";
import CountryDropdown from "./country_list";
import { FaFemale } from "react-icons/fa";
import { BiMale } from "react-icons/bi";
import { intlFormat, toDate } from "date-fns";
import companyLogo from "./img/Fortune_Logo__Gold__10.12.23_page-0001-removebg.png";
import { FiUpload } from 'react-icons/fi';

function Interview() {
  const [formSubmissionsInt, setFormSubmissionsInt] = useState([]);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [gender, setGender] = useState("");
  const [disability, setDisability] = useState("");
  const [isFresher, setIsFresher] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('');
  const [fileName, setFileName] = useState({});

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    setFormDataInt((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const [IntformData, setFormDataInt] = useState({
    candidate_surname: "",
    candidate_name: "",
    candidate_sname: "",
    candidate_age: "",
    candidate_dob: "",
    candidate_pob: "",
    candidate_father: "",
    candidate_nat: "",
    candidate_religion: "",
    candidate_caste: "",
    candidate_loc: "",
    candidate_mail: "",
    candidate_phone: "",
    candidate_alternate_phone: "",
    marital_status: "",
    candidate_matriculation: "",
    candidate_puc: "",
    candidate_graduation: "",
    candidate_pg: "",
    candidate_other: "",
    candidate_pass: "",
    candidate_pass1: "",
    candidate_pass2: "",
    candidate_pass3: "",
    candidate_pass4: "",
    candidate_marks: "",
    candidate_marks1: "",
    candidate_marks2: "",
    candidate_marks3: "",
    candidate_marks4: "",
    candidate_year: "",
    candidate_year1: "",
    candidate_year2: "",
    candidate_year3: "",
    candidate_year4: "",
    organization_name: "",
    project_topic: "",
    training_period: "",
    post_applied: "",
    qualification: "",
    date: "",
    time: "",
    fromDate: '',
    toDate: '',
    companyName: '',
    rolePosition: '',
    salary: '',
    // candidate_outtime:'',
    UploadPhoto: null,
    UploadResume: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    if (file) {
      setFormDataInt((prevData) => ({
        ...prevData,
        [name]: file
      }));
    }
  };
  

  const handleChangeInterview = (e) => {
    const { name, value } = e.target;
    setFormDataInt((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOptionChange = (event) => {
    if (event.target.value === "fresher") {
      setIsFresher("Fresher")
    }
    else {
      setIsFresher("Experience")
    }
  };


  const handleSubmitInterview = async (e) => {
    e.preventDefault();
    const formValue = {
      Surname: IntformData.candidate_surname,
      First_Name: IntformData.candidate_name,
      Second_Name: IntformData.candidate_sname,
      Age: IntformData.candidate_age,
      Date_of_Birth: IntformData.candidate_dob,
      Place_of_Birth: IntformData.candidate_pob,
      Father_name: IntformData.candidate_father,
      Nationality: selectedCountry,
      Religion: IntformData.candidate_religion,
      Caste: IntformData.candidate_caste,
      Gender: gender,
      Disability: disability,
      Address: IntformData.candidate_loc,
      Email: IntformData.candidate_mail,
      Phone_No: IntformData.candidate_phone,
      Alternate_Phone_No: IntformData.candidate_alternate_phone,
      Marital_Status: IntformData.marital_status,
      Matriculation: IntformData.candidate_matriculation,
      PUC: IntformData.candidate_puc,
      Graduation: IntformData.candidate_graduation,
      PG: IntformData.candidate_pg,
      OtherQualification: IntformData.candidate_other,
      Examination_Passed: IntformData.candidate_pass,
      Examination_Passed1: IntformData.candidate_pass1,
      Examination_Passed2: IntformData.candidate_pass2,
      Examination_Passed3: IntformData.candidate_pass3,
      Examination_Passed4: IntformData.candidate_pass4,
      Marks: IntformData.candidate_marks,
      Marks1: IntformData.candidate_marks1,
      Marks2: IntformData.candidate_marks2,
      Marks3: IntformData.candidate_marks3,
      Marks4: IntformData.candidate_marks4,
      Year: IntformData.candidate_year,
      Year1: IntformData.candidate_year1,
      Year2: IntformData.candidate_year2,
      Year3: IntformData.candidate_year3,
      Year4: IntformData.candidate_year4,
      Organization_Name: IntformData.organization_name,
      Topic: IntformData.project_topic,
      Period: IntformData.training_period,
      experienceLevel: isFresher,
      // Father_name: IntformData.father_name,
      Interview_for: IntformData.post_applied,
      Qualification: IntformData.qualification,
      fromDate: IntformData.fromDate,
      toDate: IntformData.toDate,
      companyName: IntformData.companyName,
      rolePosition: IntformData.rolePosition,
      salary: IntformData.salary,
      Date: IntformData.date,
      // candidate_purpose:'',
      time: IntformData.time,
      // candidate_outtime:'',
      Photo:IntformData.UploadPhoto,
      Resume:IntformData.UploadResume,
    };
    if (formSubmissionsInt === "No records found") {
      await submitFormData(formValue);
    } else {
      const isDuplicate = formSubmissionsInt.some(
        (entry) =>
          entry.Name === formValue.Name &&
          entry.Father_name === formValue.Father_name
      );

      if (isDuplicate) {
        setErrorMessage("Your data already stored in our database");
        setMessage("");
      } else {
        await submitFormData(formValue);
      }
    }
  };
  const submitFormData = async (formValue) => {
    try {
      const response = await axios.post('https://fortuneone.in/api/interviewphp/interviewfrom.php', formValue, {
        headers: { 'Content-Type': 'application/json' }
    });
      setShowSucess(true);
      setMessage(response.data.message);

    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };

  const handleClose = () => {
    setShowSucess(false);
  };

  return (
    <div className="shadow border bg-prime5">
      <div className="d-flex justify-content-center">

        <div className='fs-34 fw-bold ms-16 fs-20 mt-5 text-gray70'>Application Form</div>
        {/* <img src={companyLogo} alt="Company Logo" className="w-10 image-size" /> */}
      </div>
      {errorMessage && <span className="text-danger ms-4">{errorMessage}</span>}
      {message && <span className="text-success ms-4">{message}</span>}
      {/* <p className="fs-28 fw-bold"><sapn className='bordergray70'>New Interview Details</sapn></p> */}
      <Form onSubmit={handleSubmitInterview} className="mx-3 mt-16 px-4 bg-white rounded">
        <div className="d-flex border-bottom flex-dir-column">
          <div className="fs-16 w-100media">Personal Details:</div>
          <div className="row mb-16 w-100">
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Surname</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_surname"
                name="candidate_surname"
                value={IntformData.candidate_surname}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">First name</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_name"
                name="candidate_name"
                value={IntformData.candidate_name}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Second name:</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_sname"
                name="candidate_sname"
                value={IntformData.candidate_sname}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Age</Form.Label>
              <Form.Control
                type="number"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_age"
                name="candidate_age"
                value={IntformData.candidate_age}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Date of birth</Form.Label>
              <Form.Control
                type="Date"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_dob"
                name="candidate_dob"
                value={IntformData.candidate_dob}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">
                Place of birth with state
              </Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="candidate_pob"
                name="candidate_pob"
                value={IntformData.candidate_pob}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Fathers's Name</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="candidate_father"
                value={IntformData.candidate_father}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Nationality</Form.Label>
              <CountryDropdown value={selectedCountry} onChange={handleCountryChange} />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Religion</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="candidate_religion"
                value={IntformData.candidate_religion}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Caste</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="candidate_caste"
                value={IntformData.candidate_caste}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Marital status</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="marital_status"
                id="marital_status"
                value={IntformData.marital_status}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Gender</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="gender-male"
                  name="gender"
                  value="Male"
                  onChange={(e) => setGender(e.target.value)}
                  label={
                    <>
                      <BiMale style={{ marginRight: "8px" }} />
                      Male
                    </>
                  }
                />
                <Form.Check
                  type="radio"
                  id="gender-female"
                  name="gender"
                  value="Female"
                  onChange={(e) => setGender(e.target.value)}
                  label={
                    <>
                      <FaFemale style={{ marginRight: "8px" }} />
                      Female
                    </>
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Physically challenged</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  name="disability"
                  label="Yes"
                  value="Yes"
                  onChange={(e) => setDisability(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  name="disability"
                  label="No"
                  value="No"
                  onChange={(e) => setDisability(e.target.value)}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex border-bottom mt-16 flex-dir-column">
          <div className="fs-16 w-10 w-100media">Contact Details:</div>
          <div className="row mb-16 ms-3 w-100">
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Phone no</Form.Label>
              <Form.Control
                type="text"
                name="candidate_phone"
                className="border-0 bg-gray20 rounded-pill w-100"
                value={IntformData.candidate_phone}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Alternate phone no</Form.Label>
              <Form.Control
                type="text"
                name="candidate_alternate_phone"
                className="border-0 bg-gray20 rounded-pill w-100"
                value={IntformData.candidate_alternate_phone}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">E-mail ID</Form.Label>
              <Form.Control
                type="email"
                id="candidate_mail"
                name="candidate_mail"
                className="border-0 bg-gray20 rounded-pill w-100"
                value={IntformData.candidate_mail}
                onChange={handleChangeInterview}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="fs-20R">Address</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                id="candidate_loc"
                name="candidate_loc"
                className="border-0 bg-gray20 border-radius w-200%"
                rows={3}
                value={IntformData.candidate_loc}
                onChange={handleChangeInterview}
              />
            </Form.Group>
          </div>
        </div>
        <div className="d-flex border-bottom mt-16 flex-dir-column">
          <div className="fs-16 w-10 w-100media">Academic Details:</div>
          <div className="ms-4 w-100">
            <div className="row mb-2 me-16">
              <h6 className="fs-20R ms-2">Matriculation (10th std.)</h6>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  id="candidate_matriculation"
                  name="candidate_matriculation"
                  placeholder="Collage / University / Institution"
                  value={IntformData.candidate_matriculation}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  placeholder="Examination Passed"
                  name="candidate_pass"
                  id="candidate_pass"
                  value={IntformData.candidate_pass}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <select
                  id="candidate_year"
                  name="candidate_year"
                  className="form-select border-0 bg-gray20 rounded-pill w-100"
                  value={IntformData.candidate_year}
                  onChange={handleChangeInterview}
                >
                  <option value="col-md-4">Select Year</option>
                  {generateYearOptions(1980, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 rounded-pill bg-gray20 w-100 mt-2"
                  id="candidate_marks"
                  name="candidate_marks"
                  value={intlFormat.candidate_marks}
                  placeholder="Percentage / Marks"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
            </div>

            <div className="row mb-2 me-16">
              <h6 className="fs-20R ms-2">Higher Secondary (+2)</h6>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="input"
                  className="border-0 rounded-pill bg-gray20 w-100"
                  id="candidate_puc"
                  name="candidate_puc"
                  placeholder="Collage / University / Institution"
                  value={IntformData.candidate_puc}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  id="puc-passed"
                  name="candidate_pass1"
                  value={intlFormat.candidate_pass1}
                  placeholder="Examination passed"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <select
                  id="candidate_year1"
                  name="candidate_year1"
                  className="form-select border-0 bg-gray20 rounded-pill w-100"
                  value={IntformData.candidate_year1}
                  onChange={handleChangeInterview}
                >
                  <option value="col-md-4">Select Year</option>
                  {generateYearOptions(1980, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 rounded-pill bg-gray20 w-100"
                  name="candidate_marks1"
                  value={IntformData.candidate_marks1}
                  placeholder="Percentage / Marks"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
            </div>
            <div className="row mb-2 me-16">
              <h6 className="fs-20R ms-2">Graduation</h6>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="input"
                  className="border-0 rounded-pill bg-gray20 w-100"
                  id="candidate_graduation"
                  name="candidate_graduation"
                  placeholder="Collage / University / Institution"
                  value={IntformData.candidate_graduation}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  id="graduation-passed"
                  name="candidate_pass2"
                  value={IntformData.candidate_pass2}
                  placeholder="Examination passed"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <select
                  id="candidate_year2"
                  name="candidate_year2"
                  className="form-select border-0 bg-gray20 rounded-pill w-100"
                  value={IntformData.candidate_year2}
                  onChange={handleChangeInterview}
                >
                  <option value="col-md-4">Select Year</option>
                  {generateYearOptions(1980, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 rounded-pill bg-gray20 w-100 mt-2"
                  name="candidate_marks2"
                  value={IntformData.candidate_marks2}
                  placeholder="Percentage / Marks / CGPA"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
            </div>
            <div className="row mb-2 me-16">
              <h6 className="fs-20R ms-2">PG</h6>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="input"
                  className="border-0 rounded-pill bg-gray20 w-100"
                  id="candidate_pg"
                  name="candidate_pg"
                  placeholder="Collage / University / Institution"
                  value={IntformData.candidate_pg}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  id="pg-passed"
                  name="candidate_pass3"
                  value={IntformData.candidate_pass3}
                  placeholder="Examination passed"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <select
                  id="candidate_year3"
                  name="candidate_year3"
                  className="form-select border-0 bg-gray20 rounded-pill w-100"
                  value={IntformData.candidate_year3}
                  onChange={handleChangeInterview}
                >
                  <option value="col-md-4">Select Year</option>
                  {generateYearOptions(1980, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 rounded-pill bg-gray20 w-100 mt-2"
                  name="candidate_marks3"
                  value={IntformData.candidate_marks3}
                  placeholder="Percentage / Marks / CGPA"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
            </div>
            <div className="row me-16">
              <h6 className="fs-20R ms-2">Other Qualification</h6>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="input"
                  className="border-0 rounded-pill bg-gray20 w-100"
                  id="candidate_other"
                  name="candidate_other"
                  placeholder="Collage / University / Institution"
                  value={IntformData.candidate_other}
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 bg-gray20 rounded-pill w-100"
                  name="candidate_pass4"
                  value={IntformData.candidate_pass4}
                  placeholder="Examination passed"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <select
                  id="candidate_year4"
                  name="candidate_year4"
                  className="form-select border-0 bg-gray20 rounded-pill w-100"
                  value={IntformData.candidate_year4}
                  onChange={handleChangeInterview}
                >
                  <option value="col-md-4">Select Year</option>
                  {generateYearOptions(1980, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control
                  type="text"
                  className="border-0 rounded-pill bg-gray20 w-100 mt-2"
                  name="candidate_marks4"
                  value={IntformData.candidate_marks4}
                  placeholder="Percentage / Marks / CGPA"
                  onChange={handleChangeInterview}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="d-flex border-bottom mt-16 flex-dir-column">
          <div className="fs-16 w-10 w-100media">Vocational Training:</div>
          <div className="row mb-16 ms-3 w-100">
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Organization Details</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                id="organization_name"
                name="organization_name"
                value={IntformData.organization_name}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Topic of Project</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="project_topic"
                id="topic"
                value={IntformData.project_topic}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Period</Form.Label>
              <Form.Control
                type="text"
                className="border-0 bg-gray20 rounded-pill w-100"
                name="training_period"
                value={IntformData.training_period}
                onChange={handleChangeInterview}
              />
            </Form.Group>
          </div>
        </div>

        <div className="d-flex border-bottom mt-16 flex-dir-column">
          <div className="fs-16 w-10 w-100media">Application Details</div>
          <div className="row mb-16 ms-3 w-100">
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                className="border-0 bg-gray20 rounded-pill bg-gray w-100"
                value={IntformData.date}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Position</Form.Label>
              {/* <Form.Control type='text' id='post_applied' name='post_applied' className='border-0 bg-gray20 rounded-pill bg-gray w-250'
                            value={IntformData.post_applied}
                            onChange={handleChangeInterview} /> */}
              <Form.Select
                className="border-0 bg-gray20 rounded-pill bg-gray w-100"
                name="post_applied"
                value={IntformData.post_applied}
                onChange={handleSelectChange}
              >
                <option>Select Position</option>
                <option value="Back-End Developer">Back-End Developer </option>
                <option value="Junior Back-End Developer">
                  Junior Back-End Developer
                </option>
                <option value="HR Manager">HR Manager</option>
                <option value="Senior HR Manager">Senior HR Manager</option>
                <option value="System Admin">System Admin</option>
                <option value="Business Coordinatior">
                  Business Coordinatior
                </option>
                <option value="CRM Excecutive">CRM Excecutive</option>
                <option value="Senior Sales Executive">
                  Senior Sales Executive{" "}
                </option>
                <option value="Sales Manager">Sales Manager</option>
                <option value="Sales Executive">Sales Executive</option>
                <option value="CEO">CEO</option>
                <option value="Senior Legal Advocate">
                  Senior Legal Advocate
                </option>
                <option value="Legal Advocate">Legal Advocate</option>
                <option value="Junior Legal Advocate">
                  Junior Legal Advocate
                </option>
                <option value="Accounts Manager">Accounts Manager</option>
                <option value="Junior Accountant">Junior Accountant</option>
                <option value="Senior Digital Marketing">
                  Senior Digital Marketing
                </option>
                <option value="Junior Digital Marketing">
                  Junior Digital Marketing
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">Qualification</Form.Label>
              <Form.Control
                type="text"
                id="qualification"
                name="qualification"
                className="border-0 bg-gray20 rounded-pill bg-gray w-100"
                value={IntformData.qualification}
                onChange={handleChangeInterview}
              />
            </Form.Group>
            <div className="col-md-4">
              <div className="mt-1 w-60"><label htmlFor="UploadPhoto" className="uploadButton shadow p-0 px-4 py-2 text-center">
                Upload Photo <FiUpload className="icon" />
              </label><input
                  type="file"
                  id="UploadPhoto"
                  name="UploadPhoto"
                  className="hiddenInput border-radius border-0 p-0 ms-1 shadow"
                  onChange={handleChangeInterview} />
                {fileName.UploadPhoto && (
                  <span className="ms-16">{fileName.UploadPhoto}</span>
                )}
                <span>{IntformData.UploadPhoto}</span>
              </div>

            </div>
            <div className="col-md-4">
              <div className="mt-1 w-60"><label htmlFor="UploadResume" className="uploadButton shadow p-0 px-4 py-2 text-center">
                Upload Resume(PDF only) <FiUpload className="icon" />
              </label><input
                  type="file"
                  id="UploadResume"
                  name="UploadResume"
                  className="hiddenInput border-radius border-0 p-0 ms-1 shadow"
                  onChange={handleChangeInterview} />
                {fileName.UploadResume && (
                  <span className="ms-16">{fileName.UploadResume}</span>
                )}
                <span>{IntformData.UploadResume}</span>
              </div>

            </div>
            <Form.Group className="col-md-4">
              <Form.Label className="fs-20R">In Time:</Form.Label>
              <Form.Control
                type="time"
                id="time"
                name="time"
                className="border-0 bg-gray20 rounded-pill bg-gray w-100"
                value={IntformData.time}
                onChange={handleChangeInterview}
              />
            </Form.Group>
          </div>
        </div>

        <div className="d-flex mt-16 flex-dir-column">
          <div className="fs-16 w-10 w-100media">Select Experience Level</div>
          <div className="mt-2 ms-4">
            <Form.Check
              type="radio"
              id="fresher"
              label="Fresher"
              name="isFresher"
              value="Fresher"
              checked={isFresher === "Fresher"}
              onChange={(e) => setIsFresher(e.target.value)}
              className="me-3"
            />
            <Form.Check
              type="radio"
              id="experienced"
              label="Experienced"
              name="isFresher"
              value="Experienced"
              checked={isFresher === "Experienced"}
              onChange={(e) => setIsFresher(e.target.value)}
            />
            {isFresher === "Experienced" && (
              <div className="row mb-16 w-100">
                <h6>Experience Details</h6>
                <Form.Group className="col-md-4">
                  <Form.Label className="fs-20R">Date From</Form.Label>
                  <Form.Control
                    type="date"
                    className="border-0 bg-gray20 rounded-pill w-100"
                    name="fromDate"
                    value={IntformData.fromDate}
                    onChange={handleChangeInterview}
                  />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label className="fs-20R">Date To</Form.Label>
                  <Form.Control
                    type="date"
                    className="border-0 bg-gray20 rounded-pill w-100"
                    name="toDate"
                    value={IntformData.toDate}
                    onChange={handleChangeInterview}
                  />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label className="fs-20R">Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="border-0 bg-gray20 rounded-pill w-100"
                    name="companyName"
                    value={IntformData.companyName}
                    onChange={handleChangeInterview}
                  />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label className="fs-20R">Role/Position</Form.Label>
                  <Form.Control
                    type="text"
                    className="border-0 bg-gray20 rounded-pill w-100"
                    name="rolePosition"
                    value={IntformData.rolePosition}
                    onChange={handleChangeInterview}
                  />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label className="fs-20R">Salary (per annum)</Form.Label>
                  <Form.Control
                    type="text"
                    className="border-0 bg-gray20 rounded-pill w-100"
                    name="salary"
                    value={IntformData.salary}
                    onChange={handleChangeInterview}
                  />
                </Form.Group>
              </div>
            )}
          </div>
        </div>

        {/* <div className='d-flex justify-content-between'>
                            <label className='fs-12 '>Out Time:</label>
                            <input type='time' id='candidate_outtime' name='candidate_outtime' className='w-44 fs-12 my-1' 
                            value={IntformData.candidate_outtime}
                            onChange={handleChangeInterview}/>
                        </div> */}
        <div className="text-center mt-16 pb-3">
          <Button
            type="submit"
            variant="white"
            className="button-white border-0 text-dark rounded-pill shadow fs-16R"
          >
            Submit <img src={rightmark} />
          </Button>
          {/* <ExcelDownloadButton formData={formSubmissionsInt} /> */}
        </div>
      </Form>

      <Modal
        show={showSucess}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <SuccessMessage
          showSucess={showSucess}
          closeModal={handleClose}
          message={"Candidate Added Successfully"}
        />
      </Modal>
    </div>
  );
}

export default Interview;