import React, { useEffect, useState } from "react";
import './styles/style.css';
import './styles/mediaqueries.css';
import "bootstrap/dist/css/bootstrap.min.css";
import confetti from "canvas-confetti";

function SuccessMessage({ showSucess, closeModal, message }) {
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

    const triggerBlast = () => {
        const end = Date.now() + 1.5 * 1000;
        const interval = setInterval(() => {
            if (Date.now() > end) {
                clearInterval(interval);
            }
            confetti({
                particleCount: 100,
                startVelocity: 30,
                spread: 360,
                ticks: 60,
                origin: {
                    x: Math.random(),
                    y: Math.random() - 0.2,
                },
            });
        }, 200);
    };

    useEffect(() => {
        if (showSucess) {
            setShowSuccessAnimation(true);
            triggerBlast();
            const timer = setTimeout(() => {
                closeModal();
            }, 3000);

            return () => clearTimeout(timer); 
        }
    }, [showSucess, closeModal]);

    return (
        <div className="content d-flex justify-content-center">
            <div className="celebration-container">
                <svg
                    width="200"
                    height="200"
                    viewBox="0 0 400 400"
                    className="celebration-svg"
                >
                    <circle
                        fill="none"
                        stroke="#51A3A3"
                        strokeWidth="10"
                        cx="200"
                        cy="200"
                        r="150"
                        strokeLinecap="round"
                        transform="rotate(-90 200 200)"
                        className="circle-animation"
                    />
                    <polyline
                        fill="none"
                        stroke="#51A3A3"
                        points="88,214 173,284 304,138"
                        strokeWidth="16"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="tick-animation"
                    />
                </svg>
                <div className="fs-16 text-center">{message}</div>
            </div>
        </div>
    );
}

export default SuccessMessage;
